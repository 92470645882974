import React from 'react';
import { alpha } from '@theme-ui/color';

import {
  Flex,
  Box,
  Text,
  Grid,
  Container,
} from 'components';
import { useIntl } from 'context/IntlContext';
import Card from './icons/card.svg';
import Account from './icons/account.svg';
import Marketplace from './icons/marketplace.svg';
import Transfers from './icons/transfer.svg';
import Deposits from './icons/deposit.svg';

const WeBelieveFree = () => {
  const { t } = useIntl();

  return (
    <Grid
      columns={['1fr', '1fr', '1fr', '1fr 1fr']}
      sx={{
        columnGap: '160px',
      }}
    >
      <Box sx={{ gridColumn: '1', gridRow: '1' }}>
        <Text
          variant="sectionTitle"
          sx={{
            mb: 6,
          }}
        >
          {t('homepage.home.all_should_be_free_section.title')}
        </Text>
        <Text
          variant="underHero"
          sx={{
            mb: 6,
            color: alpha('quaternary', 0.8),
          }}
        >
          {t('homepage.home.all_should_be_free_section.subtitle')}
        </Text>
      </Box>
      <Box sx={{ gridColumn: '1', gridRow: ['3', '3', '3', '2'] }}>
        <Text
          variant="contentTitle"
          sx={{
            mb: 6,
          }}
        >
          {t('homepage.home.all_should_be_free_section.description.title')}
        </Text>
        <Text
          sx={{
            color: alpha('quaternary', 0.8),
          }}
        >
          {t('homepage.home.all_should_be_free_section.description.subtitle')}
        </Text>
      </Box>
      <Box
        sx={{
          gridColumn: ['1', '1', '1', '2'],
          gridRow: ['2/3', '2/3', '2/3', '1/3'],
        }}
      >
        {/* <Flex
          sx={{
            bg: alpha('primary', 0.05),
            justifyContent: 'space-between',
            py: 1,
            pl: 2,
            pr: 6,
            borderRadius: 3,
            mb: 3,
            alignItems: 'center',
          }}
        >
          <Flex sx={{ flexGrow: 1, alignItems: 'center' }}>
            <Card />
            <Text
              as="span"
              sx={{
                fontWeight: 'semibold',
                pl: 1,
              }}
            >
              {t('homepage.home.all_should_be_free_section.card_item.title')}
            </Text>
          </Flex>
          <Text
            as="span"
            sx={{
              textAlign: 'right',
              color: '#31ac55',
              fontWeight: 'bold',
            }}
          >
            {t('global.free')}
          </Text>
        </Flex> */}
        <Flex
          sx={{
            bg: alpha('primary', 0.05),
            justifyContent: 'space-between',
            py: 1,
            pl: 2,
            pr: 6,
            borderRadius: 3,
            mb: 3,
            alignItems: 'center',
          }}
        >
          <Flex sx={{ flexGrow: 1, alignItems: 'center' }}>
            <Account />
            <Text
              as="span"
              sx={{
                fontWeight: 'semibold',
                pl: 1,
              }}
            >
              {t('homepage.home.all_should_be_free_section.account_item.title')}
            </Text>
          </Flex>
          <Text
            sx={{
              textAlign: 'right',
              color: '#31ac55',
              fontWeight: 'bold',
            }}
          >
            {t('global.free')}
          </Text>
        </Flex>
        <Flex
          sx={{
            bg: alpha('primary', 0.05),
            justifyContent: 'space-between',
            py: 1,
            pl: 2,
            pr: 6,
            borderRadius: 3,
            mb: 3,
            alignItems: 'center',
          }}
        >
          <Flex sx={{ flexGrow: 1, alignItems: 'center' }}>
            <Marketplace />
            <Text
              as="span"
              sx={{
                fontWeight: 'semibold',
                pl: 1,
              }}
            >
              {t(
                'homepage.home.all_should_be_free_section.marketplace_item.title',
              )}
            </Text>
          </Flex>
          <Text
            sx={{
              textAlign: 'right',
              color: '#31ac55',
              fontWeight: 'bold',
            }}
          >
            {t('global.free')}
          </Text>
        </Flex>
        <Flex
          sx={{
            bg: alpha('primary', 0.05),
            justifyContent: 'space-between',
            py: 1,
            pl: 2,
            pr: 6,
            borderRadius: 3,
            mb: 3,
            alignItems: 'center',
          }}
        >
          <Flex sx={{ flexGrow: 1, alignItems: 'center' }}>
            <Transfers />
            <Text
              as="span"
              sx={{
                fontWeight: 'semibold',
                pl: 1,
              }}
            >
              {t(
                'homepage.home.all_should_be_free_section.transfers_item.title',
              )}
            </Text>
          </Flex>
          <Text
            sx={{
              textAlign: 'right',
              color: '#31ac55',
              fontWeight: 'bold',
            }}
          >
            {t('global.free')}
          </Text>
        </Flex>
        <Flex
          sx={{
            bg: alpha('primary', 0.05),
            justifyContent: 'space-between',
            py: 1,
            pl: 2,
            pr: 6,
            borderRadius: 3,
            mb: 3,
            alignItems: 'center',
          }}
        >
          <Flex sx={{ flexGrow: 1, alignItems: 'center' }}>
            <Deposits />
            <Text
              as="span"
              sx={{
                fontWeight: 'semibold',
                pl: 1,
              }}
            >
              {t(
                'homepage.home.all_should_be_free_section.deposits_and_withdrawals_item.title',
              )}
            </Text>
          </Flex>
          <Text
            sx={{
              textAlign: 'right',
              color: '#31ac55',
              fontWeight: 'bold',
            }}
          >
            {t('global.free')}
          </Text>
        </Flex>
      </Box>
    </Grid>
  );
};

export default WeBelieveFree;
