import React, { FC, ReactElement } from 'react';

import { Box, Text, Heading, Grid } from 'components';

import Section from '../Section';
import { useIntl } from 'react-intl';
interface TopCTAProps {
  image: ReactElement;
  heading: string;
  subtitle: string;
}

const TopCTA: FC<TopCTAProps> = ({ image, heading, subtitle, children }) => {
  const { locale } = useIntl();

  const isSpanishPage = locale === 'es';
  const isPortuguesePage = locale === 'pt';

  return (
    <Section
      outterSX={{ bg: 'tertiaryBackground' }}
      innerSX={{
        pb: isPortuguesePage || isSpanishPage ? [165, 175, 185, 60, 60] : [44, 44, 44, 0, 0],
      }}
      noDivider
    >
      <Grid gap={14} columns={['3fr', '3fr', '3fr', '1fr 1fr']}>
        <Box>
          <Heading
            as="h1"
            sx={{
              variant: 'text.heroTitle',
              color: 'tertiary',
              mb: [0, 0, 0, 70, 70],
            }}
          >
            {heading}
          </Heading>
          <Text
            as="p"
            variant="underHero"
            sx={{
              color: 'tertiary',
              opacity: '0.8',
              mb: [0, 6, 0, 0],
            }}
          >
            {subtitle}
          </Text>
        </Box>
        <Box
          sx={{
            height: ['auto', 'auto', 'auto', 514, 514],
            display: ['none', 'none', 'none', 'inline-block', 'inline-block'],
            position: 'relative',
            width: '100%',
            verticalAlign: 'middle',
            overflow: 'hidden',
            '& > svg': {
              verticalAlign: 'bottom',
            },
          }}
        >
          <Box sx={{ position: 'absolute', bottom: 0 }}>{image}</Box>
        </Box>
      </Grid>
      {children}
    </Section>
  );
};

export default TopCTA;
