import React, { FC } from 'react';
import { useIntl } from 'context/IntlContext';
import { Box, Grid, Link, Text } from 'components';
import Motto from '../Motto';

const FAQHome: FC<{}> = () => {
  const { t } = useIntl();

  return (
    // TODO: ADD Lokalize keys
    <>
      <Text mb={5} variant="sectionTitle">
        {t('homepage.general.faq_section.title')}
      </Text>
      <Grid gap={9} columns={[1, '1fr', '1fr 1fr']}>
        <Motto
          title={t('homepage.general.faq_section.faq1.question')}
          description={t('homepage.general.faq_section.faq1.answer')}
        />
        <Motto
          title={t('homepage.general.faq_section.faq2.question')}
          description={t('homepage.general.faq_section.faq2.answer')}
        />
        <Motto
          title={t('homepage.general.faq_section.faq3.question')}
          description={t('homepage.general.faq_section.faq3.answer')}
        />
        <Motto
          title={t('homepage.general.faq_section.faq4.question')}
          description={t('homepage.general.faq_section.faq4.answer')}
        />
        <Motto
          title={t('homepage.general.faq_section.faq5.question')}
          description={t('homepage.general.faq_section.faq5.answer')}
        />
      </Grid>
    </>
  );
};

export default FAQHome;
