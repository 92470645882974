import React from 'react';
import {
  Flex,
  Button,
  Text,
  Box,
  Image,
  Grid,
} from 'components';

import { useIntl } from 'context/IntlContext';
import Link from 'components/Link';
import Covid from '../../../Marketplace/TopSection/TabContent/Donations/icons/covid.png';
import { TabTitle } from '../styled';

const Donations = () => {
  const { t } = useIntl();
  return (
    <Flex
      sx={{
        justifyContent: 'stretch',
        alignItems: 'center',
        flexDirection: ['column'],
        marginTop: '80px',
        '@media screen and (max-width: 768px)': {
          marginTop: '44px'
        }
      }}
    >
      <Grid
        columns={['1fr', '1fr', '1fr', '1fr 1fr']}
        sx={{
          columnGap: '72px',
          gridRowGap: 0,
        }}
      >
        <Box>
          <Button
            variant="primary"
            sx={{
              flexShrink: 0,
              width: ['100%', '100%', 'auto'],
              bg: 'quaternaryBackground',
              color: 'primary',
              pb: 5,
            }}
          >
            {t('homepage.marketplace.donations.tab.section_titlename')}
          </Button>
          <TabTitle
            sx={{
              mb: [7],
              pt: 5,
            }}
          >
            {t('homepage.marketplace.donations.tab.section_title')}
          </TabTitle>
          <Text as="p" variant="underHero" sx={{ mb: 9 }}>
            {t('homepage.marketplace.donations.tab.section_summary')}
          </Text>
          <Box
            sx={{
              display: ['initial', 'initial', 'initial', 'none'],
              textAlign: 'initial',
            }}
          >
            <Image
              src={Covid}
              alt="Covid"
              sx={{
                m: '0 auto',
                width: 'inherit',
                height: 'auto',
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: ['stretch', 'stretch', 'stretch', 'center'],
              flexDirection: ['column', 'column', 'column', 'row'],
              pb: [3, 3, 3, 0],
            }}
          >
            <Button
              as={Link}
              to={t(
                'homepage.marketplace.donations.cta_section.cta_button.link',
              )}
              variant="primary"
              sx={{
                mr: [0, 0, 0, 3],
                mb: [3, 3, 3, 0],
              }}
            >
              {t('homepage.marketplace.donations.tab.section_button')}
            </Button>
            <Button as={Link} to="/marketplace#donations" variant="secondary">
              {t('global.buttons.learn_more')}
            </Button>
          </Box>
          <Text
            sx={{
              color: '#233143',
              fontSize: '12px',
              lineHeight: '24px',
              marginTop: '40px',
              textAlign: 'left',
            }}>
            {t('marketplace.provider.cta.warning')}
          </Text> 
        </Box>
        <Box
          sx={{
            textAlign: 'initial',
            display: ['none', 'none', 'none', 'initial'],
          }}
        >
          <Image
            src={Covid}
            alt="Covid"
            sx={{
              m: '0 auto',
              width: 'inherit',
              height: 'auto',
            }}
          />
        </Box>
      </Grid>
    </Flex>
  );
};

export default Donations;
