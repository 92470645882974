import React from 'react';
import {
  Flex,
  Button,
  Text,
  Box,
  Image,
  Grid,
} from 'components';

import { useIntl } from 'context/IntlContext';
import Link from 'components/Link';

import business_loans from '../../../Marketplace/TopSection/TabContent/CashConversionCycle/icons/business_loans.png';
import change_capital from '../../../Marketplace/TopSection/TabContent/CashConversionCycle/icons/change_capital_logo.png';

const CashConversionCycle = () => {
  const { t } = useIntl();
  return (
    <Grid
      columns={['1fr', '1fr', '1fr', '1fr 1fr']}
      sx={{
        columnGap: '0px',
        textAlign: 'initial',
        marginTop: '80px',
        '@media screen and (max-width: 768px)': {
          marginTop: '44px'
        }
      }}
    >
      <Box
        sx={{
          display: 'block',
          gridColumn: ['1', '1', '1', '2'],
          mb: [11, 11, 11, 0],
          gridRow: ['2', '2', '2', '1/4'],
          pr: '20px',
        }}
      >
        <Image
          src={business_loans}
          alt="Business Loans"
          sx={{
            display: 'block',
            width: '533px',
            height: '360px',
            margin: '0 0 0 0px',
            objectFit: 'contain',
          }}
        />
      </Box>
      <Box
        sx={{
          gridColumn: '1',
          gridRow: '1',
          pr: [0, 0, '81px'],
        }}
      >
        <Image
          src={change_capital}
          alt="Change Capital"
          sx={{
            display: 'block',
            width: '150px',
            height: '33px',
            margin: '0 302px 31px 0',
            objectFit: 'contain',
          }}
        />
        <Text mb={5} variant="sectionTitle">
          {t('marketplace.cash_conversion_cycle.atf.title')}
        </Text>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Text
            variant="contentTitle"
            sx={{
              fontWeight: 'normal',
              opacity: '0.8',
            }}
          >
            {t('marketplace.cash_conversion_cycle.atf.subtitle')}
          </Text>
        </Box>
      </Box>
      <Box>
        <Button
          as={Link}
          to={t('marketplace.cash_conversion_cycle.atf.cta_link')}
          variant="primary"
          sx={{ flexShrink: 0, width: ['100%', '100%', 'auto'] }}
        >
          {t('marketplace.cash_conversion_cycle.atf.cta_value')}
        </Button>
      </Box>
    </Grid>
  );
};

export default CashConversionCycle;
